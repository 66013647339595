// App.js
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    // Redirect to abc.com
    window.location.href = 'https://avalabs.org';
  }, []);
  return null; // No UI needed as we are immediately redirecting
}

export default App;
